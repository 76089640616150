<template>
  <div class="categoriesPage">
    <HeadBar titleName="热门分类"></HeadBar>
    <div class="content oepnX" v-if="!isLoading">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :error="error"
        :is-higeht-max="true"
        :adv-list="advList"
        :hasAdvPagination="true"
        className="TopicPage"
      >
        <div class="labelTitle" v-show="showLabelTitle">{{ labelName }}</div>
        <div class="classify">
          <div class="classifyItem">
            <div class="classifyType">
              <span>{{ tagTypeData.name }}</span>
            </div>
            <div class="classifyTypeValue">
              <div v-for="(item, index) in tagTypeData.tags" :key="index">
                <!--                                <div class="classifyLabel" v-if="!tagTypeData.isAn && index < 4"-->
                <!--                                    @click="onTagLabel(tagTypeData,item,index)"-->
                <!--                                    :class="{classifyItemActive:tagTypeData.activeIndex == index}">-->
                <!--                                    <span>{{item.name}}</span>-->
                <!--                                </div>-->
                <!--                                <div class="classifyLabel" v-if="tagTypeData.isAn"-->
                <!--                                    @click="onTagLabel(tagTypeData,item,index)"-->
                <!--                                    :class="{classifyItemActive:tagTypeData.activeIndex == index}">-->
                <!--                                    <span>{{item.name}}</span>-->
                <!--                                </div>-->
                <div
                  class="classifyLabel"
                  @click="onTagLabel(tagTypeData, item, index)"
                  :class="{
                    classifyItemActive: tagTypeData.activeIndex == index,
                  }"
                >
                  <span>{{ item.name }}</span>
                </div>
              </div>
              <!--                            <div class="unfoldBtn classifyLabel" @click="switchTag(tagTypeData)">-->
              <!--&lt;!&ndash;                                <span>{{tagTypeData.isAn?"收起":"展开"}}</span>&ndash;&gt;-->
              <!--                                <span>-->
              <!--                                 <svg-icon v-if="tagTypeData.isAn" icon-class="packUp" ></svg-icon>-->
              <!--                                 <svg-icon v-else icon-class="unfold" ></svg-icon>-->
              <!--                                </span>-->
              <!--                            </div>-->
            </div>
          </div>

          <div
            class="classifyItem"
            v-for="(item, index) in resultList"
            :key="index"
          >
            <div class="classifyType">
              <span>{{ item.name }}</span>
            </div>
            <div class="classifyTypeValue">
              <div v-for="(itemTag, indexTag) in item.tags" :key="indexTag">
                <!--                                <div class="classifyLabel" v-if="!item.isAn && indexTag < 5"-->
                <!--                                    @click="onTagLabelCondition(item,itemTag,indexTag)"-->
                <!--                                    :class="{classifyItemActive:item.activeIndex == indexTag}">-->
                <!--                                    <span>{{itemTag.name}}</span>-->
                <!--                                </div>-->
                <!--                                <div class="classifyLabel" v-if="item.isAn"-->
                <!--                                    @click="onTagLabelCondition(item,itemTag,indexTag)"-->
                <!--                                    :class="{classifyItemActive:item.activeIndex == indexTag}">-->
                <!--                                    <span>{{itemTag.name}}</span>-->
                <!--                                </div>-->
                <div
                  class="classifyLabel"
                  @click="onTagLabelCondition(item, itemTag, indexTag)"
                  :class="{ classifyItemActive: item.activeIndex == indexTag }"
                >
                  <span>{{ itemTag.name }}</span>
                </div>
              </div>
              <!--                            <div class="unfoldBtn classifyLabel" @click="switchTag(item)">-->
              <!--&lt;!&ndash;                                <span>{{item.isAn?"收起":"展开"}}</span>&ndash;&gt;-->
              <!--                              <svg-icon v-if="item.isAn" icon-class="packUp" ></svg-icon>-->
              <!--                              <svg-icon v-else icon-class="unfold" ></svg-icon>-->
              <!--                            </div>-->
            </div>
          </div>
        </div>
        <van-sticky offset-top="1.2rem" @change="changeSticky">
          <div></div>
        </van-sticky>
        <!-- <van-sticky class="labelTitle">
                  <div>123123123</div>
                </van-sticky> -->
        <div class="contentVideo">
          <VerticleThreeList
            :videoList="videoList"
            v-if="isVertical"
          ></VerticleThreeList>
          <TransverselyTwoList
            :videoList="videoList"
            v-else
          ></TransverselyTwoList>
          <!-- 猜你想看 -->
          <div v-if="videoHotList && videoHotList.length > 0" class="guess">
            <div class="guessTitle">
              <span>猜你想看</span>
            </div>
            <VerticleThreeList
              :videoList="videoHotList"
              v-if="isVertical"
            ></VerticleThreeList>
            <TransverselyTwoList
              :videoList="videoHotList"
              v-else
            ></TransverselyTwoList>
          </div>
        </div>
      </PullRefresh>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import { AdType, getAdItem } from "@/utils/getConfig";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import HeadBar from "@/components/HeadBar";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import {
  queryHotkindTagsList,
  queryTagsmediaList,
  queryHotInfoList,
} from "@/api/video";
export default {
  name: "categoriesPage",
  components: {
    HeadBar,
    PullRefresh,
    Loading,
    VerticleThreeList,
    TransverselyTwoList,
  },
  data() {
    return {
      tagTypeData: {
        name: "类型",
        isAn: false,
        activeIndex: 0,
        parTages: null,
        tags: [
          {
            name: "动漫",
            kind: 0,
          },
          {
            name: "漫画",
            kind: 1,
          },
          {
            name: "真人",
            kind: 3,
          },
          {
            name: "写真",
            kind: 5,
          },
          {
            name: "小说",
            kind: 4,
          },
          {
            name: "原作",
            kind: 6,
          },
        ],
      },
      resultList: [],
      pageNumber: 1,
      pageSize: 20,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      advList: [],
      videoList: [],
      format: 0,
      showLabelTitle: false,
      tagTitle: null,
      labelName: "动漫",
      videoHotList: [],
      oldVideoList: [],
      listAdvs: [],
      listAdvsVerticel: [],
      isVertical: true,
      isTypeLoading: 0, //书籍加载，如果加载完，就加载推荐（0：加载书籍数据，1：加载推荐数据）
    };
  },
  created() {
    this.listAdvs = getAdItem(AdType.CLASSIFICARION);
    this.listAdvsVerticel = getAdItem(AdType.CLASSIFICARION_VERTICLE);
    // console.log(this.listAdvs,"----------------")
    // console.log(this.listAdvsVerticel,"+++++++++++++++")
    // console.log(this.listAdvs)
  },
  mounted() {
    this.parTages = new Set();
    this.tagTitle = new Set();
    this.getHotkindTagsList();
    this.getTagsmediaList();
  },
  methods: {
    async getHotkindTagsList() {
      let req = {
        kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind,
      };
      let res = await this.$Api(queryHotkindTagsList, req);

      if (res && res.code == 200) {
        res.data.list.forEach((iterator) => {
          iterator.isAn = false;
        });
        this.resultList = res.data.list;
      }
    },
    changeSticky() {
      this.showLabelTitle = !this.showLabelTitle;
    },
    switchTag(item) {
      item.isAn = !item.isAn;
    },
    onTagLabel(tagTypeData, item, index) {
      tagTypeData.activeIndex = index;
      this.isVertical = item.kind != 3;
      // this.labelName = tagTypeData.tag[index].name;
      this.labelName = this.tagTypeData.tags[this.tagTypeData.activeIndex].name;
      this.parTages.clear();
      this.tagTitle.clear();
      this.videoHotList = [];
      this.isTypeLoading = 0;
      this.getHotkindTagsList();
      this.pageNumber = 1;
      this.finished = false;
      this.isNoData = false;
      this.getTagsmediaList("refresh");
    },
    onTagLabelCondition(tagTypeData, item, index) {
      this.isTypeLoading = 0;
      this.videoHotList = [];
      if (tagTypeData.activeIndex == index) {
        tagTypeData.activeIndex = undefined;
        this.parTages.delete(item.id);
        this.tagTitle.delete(item.name);
      } else {
        if (tagTypeData.activeIndex != undefined) {
          this.parTages.delete(tagTypeData.tags[tagTypeData.activeIndex].id);
          this.tagTitle.delete(tagTypeData.tags[tagTypeData.activeIndex].name);
          this.parTages.add(item.id);
          this.tagTitle.add(item.name);
        } else {
          this.parTages.add(item.id);
          this.tagTitle.add(item.name);
        }

        tagTypeData.activeIndex = index;
        // console.log(this.tagTitle)
      }
      // console.log(this.tagTitle)
      if (Array.from(this.tagTitle).length > 0) {
        this.labelName =
          this.tagTypeData.tags[this.tagTypeData.activeIndex].name +
          "•" +
          Array.from(this.tagTitle).join("•");
      } else {
        this.labelName =
          this.tagTypeData.tags[this.tagTypeData.activeIndex].name;
      }
      this.$forceUpdate();
      this.pageNumber = 1;
      this.finished = false;
      this.isNoData = false;
      this.getTagsmediaList("refresh");
    },
    async getTagsmediaList(type) {
      if (type) {
        this.isTypeLoading = 0;
      }
      let req = {
        kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      if (this.parTages.size > 0) {
        req.tags = Array.from(this.parTages);
      }

      let res = await this.$Api(queryTagsmediaList, req);

      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      try {
        if (res && res.code == 200) {
          if (res.data.MediaList) {
            if (type == "refresh") {
              this.oldVideoList = res.data.MediaList || [];
            } else {
              this.oldVideoList = this.oldVideoList.concat(res.data.MediaList);
            }

            if (
              (this.isVertical && this.listAdvs.length) > 0 ||
              (!this.isVertical && this.listAdvsVerticel.length) > 0
            ) {
              // this.oldVideoList = this.oldVideoList.concat(list);
              let advNum = Math.floor(this.oldVideoList.length / 4);
              this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
              let advLength = this.isVertical
                ? this.listAdvs.length
                : this.listAdvsVerticel.length;
              for (let i = 0; i < advNum; i++) {
                let advItem = this.isVertical
                  ? this.listAdvs[i % advLength]
                  : this.listAdvsVerticel[i % advLength];
                this.videoList.splice(5 * i + 4, 0, {
                  adv: advItem,
                });
              }
            } else {
              this.videoList = [].concat(this.oldVideoList);
            }
          } else if (this.pageNumber == 1) {
            this.videoList = [];
          }
        //   console.log(this.videoList);
          if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
            // this.finished = true;
            this.isTypeLoading = 1;
            this.getHotInfoList("refresh");
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    async getHotInfoList(type) {
      /**
       * 猜你喜欢
       * @type {{pageNumber: number, kind: number, pageSize: number}}
       */
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        kind: this.tagTypeData.tags[this.tagTypeData.activeIndex].kind,
      };
      let res = await this.$Api(queryHotInfoList, req);

      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      if (res && res.code == 200) {
        if (res.data.MediaList && res.data.MediaList.length > 0) {
          if (type == "refresh") {
            this.videoHotList = res.data.MediaList;
          } else {
            this.videoHotList = this.videoHotList.concat(res.data.MediaList);
          }
        }

        // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
        //     this.isNoData = true;
        // }
        if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
          this.finished = true;
        }
      }
    },
    onRefresh() {
      this.isTypeLoading = 0;
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.loading = true;
      this.videoHotList = [];
      this.getTagsmediaList("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      if (this.isTypeLoading == 0) {
        this.getTagsmediaList();
      } else {
        this.getHotInfoList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.categoriesPage {
  height: 100%;

  .content {
    height: calc(100% - 44px);
    //padding: 0 12px;
    //margin-top: 18px;

    .classify {
      margin-bottom: 12px;

      .classifyItemActive {
        color: rgb(0, 0, 0);
        font-weight: bolder;
      }

      .classifyItem {
        display: flex;
        font-size: 14px;
        color: #999999;
        font-weight: 400;

        .classifyType {
          width: 82px;
          margin-right: 20px;
          margin-top: 18px;
          margin-left: 16px;
          font-weight: bolder;
        }
        .classifyType:last-child {
          margin-right: 0;
        }

        .classifyTypeValue {
          display: flex;
          justify-content: space-between;
          //flex-wrap: wrap;
          width: calc(100% - 16px);
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          .classifyLabel {
            width: 100%;
            margin-right: 50px;
            margin-top: 18px;
          }
        }

        .unfoldBtn {
          position: absolute;
          right: 0;
          //margin-right: 30px;
          margin-top: 18px;
          font-weight: bolder;
          color: #999999;
          .svg-icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .contentVideo {
      padding: 0 12px;
    }

    .labelTitle {
      height: 42px;
      width: 100%;
      font-size: 15px;
      line-height: 42px;
      text-align: center;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      color: #000;
      background: rgba(255, 255, 255, 0.81);
      position: fixed;
      top: 45px;
      z-index: 2;
      // position: fixed;
      // top: -10000px;
      // /deep/ .van-sticky--fixed {
      //     height: 42px;
      //     line-height: 42px;
      //     width: 100%;
      //     font-size: 15px;
      //     text-align: center;
      //     display: block !important;
      //     top: 45px;
      //     background: rgba(255, 255, 255, 0.81);
      // }
    }
  }
  .guess {
    .guessTitle {
      color: rgb(0, 0, 0);
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 9px;
    }
  }
}
</style>
